import ChristmasCursorImg from "../../Assets/christmas_cursor.gif";
import ChristmasHatImg from "../../Assets/christmas_hat.png";

export function ChristmasHat({ avatar }) {
	if (
		avatar === "profile" &&
		import.meta.env.VITE_CELEBRATION === "christmas" &&
		localStorage.getItem("disableCelebration") === "false"
	)
		return (
			<img
				className="ChristmasHat"
				src={ChristmasHatImg}
				alt="Christmas Hat"
			/>
		);
	return null;
}

export function ChristmasCursor() {
	if (
		import.meta.env.VITE_CELEBRATION !== "christmas" ||
		localStorage.getItem("disableCelebration") === "true"
	)
		return null;
	return (
		<img className="ChristmasMouse" src={ChristmasCursorImg} alt="cursor" />
	);
}

export function ChristmasSnowFlake() {
	if (
		import.meta.env.VITE_CELEBRATION !== "christmas" ||
		localStorage.getItem("disableCelebration") === "true"
	)
		return null;
	return (
		<div className="snowflakes" aria-hidden="true">
			<div className="snowflake">❅</div>
			<div className="snowflake">❆</div>
			<div className="snowflake">❅</div>
			<div className="snowflake">❆</div>
			<div className="snowflake">❅</div>
			<div className="snowflake">❆</div>
			<div className="snowflake">❅</div>
			<div className="snowflake">❆</div>
			<div className="snowflake">❅</div>
			<div className="snowflake">❆</div>
			<div className="snowflake">❅</div>
			<div className="snowflake">❆</div>
		</div>
	);
}

export function ChristmasLights() {
	if (
		import.meta.env.VITE_CELEBRATION !== "christmas" ||
		localStorage.getItem("disableCelebration") === "true"
	)
		return null;
	return (
		<ul className="lightrope">
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
			<li />
		</ul>
	);
}
