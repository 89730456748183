import { faCopy, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Box } from "@mantine/core";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import SauronMantineDataTable from "../Sauron/SauronMantineDataTable";
import LoginRow from "../Utils/LoginRow";
import {
	adm_status_values,
	compare,
	createIntuituADMOption,
	createIntuituPedagoOption,
	pedago_status_values,
} from "../Utils/Utils";
import BulkImportButton from "./BulkImport";
import { ADMStatus } from "./Columns/ADMStatus";
import AlertStatus from "./Columns/AlertStatus";
import { Comment } from "./Columns/Comment";
import { PedagoStatus } from "./Columns/PedagogicalStatus";
import { useSingleIntuituPersonaeMutation } from "./Queries";

export default function IntuituDataTable({
	data = [],
	exportConfig,
	isLoading,
	isFetching,
}) {
	const adm_status_options = adm_status_values.map((admStatus) =>
		createIntuituADMOption(admStatus)
	);
	const pedago_status_options = pedago_status_values.map((admStatus) =>
		createIntuituPedagoOption(admStatus)
	);
	const mutation = useSingleIntuituPersonaeMutation();

	const handleSaveRow = async ({ table, row, values }) => {
		mutation.mutate([
			{
				login: row.original.login,
				adm_status: values.adm_status,
				pedago_status: values.pedago_status,
				comment: values.comment,
			},
		]);
		table.setEditingRow(null);
	};

	const columns = [
		{
			accessorKey: "alert",
			header: t("status").capfirst(),
			size: 50,
			sortingFn: (a, b) => {
				const weight = {
					null: 0,
					late: 1,
					very_late: 2,
					not_defined: 3,
				};
				return compare(
					weight[a.original.alert],
					weight[b.original.alert]
				);
			},
			Cell: ({ row }) => <AlertStatus status={row.original.alert} />,
			enableEditing: false,
			enableGlobalFilter: false,
		},
		{
			header: t("login").capfirst(),
			accessorKey: "login",
			Cell: ({ row }) => (
				<LoginRow
					login={row.original.login}
					pageTooltip="intuit-personae-student-tooltip"
				/>
			),
			size: 200,
			enableEditing: false,
		},
		{
			accessorKey: "promotion",
			header: t("promotion").capfirst(),
			size: 50,
			enableEditing: false,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "adm_status",
			header: t("administrative status").capfirst(),
			Cell: ({ row }) => (
				<ADMStatus admStatus={row.original.adm_status} />
			),
			sortingFn: (a, b) => {
				if (a.original.adm_status === b.original.adm_status) return 0;
				if (a.original.adm_status === null) return -1;
				if (b.original.adm_status === null) return 1;
				return compare(a.original.adm_status, b.original.adm_status);
			},
			size: 150,
			editVariant: "select",
			mantineEditSelectProps: () => ({
				data: adm_status_options,
				onChange: (value) => value,
			}),
			enableGlobalFilter: false,
		},
		{
			accessorKey: "pedago_status",
			header: t("pedagogical status").capfirst(),
			Cell: ({ row }) => (
				<PedagoStatus pedagoStatus={row.original.pedago_status} />
			),
			sortingFn: (a, b) => {
				if (a.original.pedago_status === b.original.pedago_status)
					return 0;
				if (a.original.pedago_status === null) return -1;
				if (b.original.pedago_status === null) return 1;
				return compare(
					a.original.pedago_status,
					b.original.pedago_status
				);
			},
			size: 150,
			editVariant: "select",
			mantineEditSelectProps: () => ({
				data: pedago_status_options,
				onChange: (value) => value,
			}),
			enableGlobalFilter: false,
		},
		{
			accessorKey: "comment",
			header: t("comment").capfirst(),
			Cell: ({ row }) => <Comment comment={row.original.comment} />,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "last_update",
			header: t("last update").capfirst(),
			Cell: ({ row }) =>
				row.original.last_update.toLocaleString(DateTime.DATE_SHORT),
			size: 100,
			enableEditing: false,
			enableGlobalFilter: false,
		},
	];

	return (
		<SauronMantineDataTable
			title={"intuitu personae".capfirst()}
			subtitle={t("per students")}
			columns={columns}
			data={data}
			exportConfig={exportConfig}
			isLoading={isLoading}
			isFetching={isFetching}
			isCard
			toolbarInternalActionsBonus={<BulkImportButton />}
			tableProps={{
				editDisplayMode: "row",
				enableEditing: true,
				positionActionsColumn: "last",
				onEditingRowSave: handleSaveRow,
				renderRowActions: ({ row, table }) => (
					<Box sx={{ display: "flex", gap: "8px" }}>
						<ActionIcon
							title={t("edit").capfirst()}
							onClick={() => {
								table.setEditingRow(row);
							}}
						>
							<FontAwesomeIcon icon={faEdit} size="lg" />
						</ActionIcon>
						<ActionIcon
							title={t("duplicate").capfirst()}
							onClick={() => {
								if (
									row.original.adm_status === null ||
									row.original.pedago_status === null
								) {
									toast.error(
										`Intuitu Personae: ${t(
											'you can\'t duplicate an entry with a "N/A" value'
										).capfirst()}`,
										{
											theme: "colored",
											autoClose: 7500,
										}
									);
									return;
								}
								mutation.mutate([
									{
										login: row.original.login,
										comment: row.original.comment,
										adm_status: row.original.adm_status,
										pedago_status:
											row.original.pedago_status,
									},
								]);
							}}
						>
							<FontAwesomeIcon icon={faCopy} size="lg" />
						</ActionIcon>
					</Box>
				),
			}}
		/>
	);
}
