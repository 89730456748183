import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import CommonDatatableContainer from "../Internship-PartTime/CommonDatatableContainer";
import CheaterButtons from "../Utils/CheaterButtons";
import LoginRow from "../Utils/LoginRow";
import {
	useTepitechCheatingQuery,
	useTepitechCheatingStatusMutation,
} from "./Queries";

export default function TepitechCheatersDataTableContainer({
	cities,
	curriculums,
	promotions,
	areRequestEnabled,
}) {
	const citiesValues = [];
	cities.forEach((city) => citiesValues.push(city.value));

	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		error,
		isLoading,
		isFetching,
	} = useTepitechCheatingQuery(
		cities.extract("value"),
		curriculums,
		promotions,
		areRequestEnabled
	);
	const cheatingMutation = useTepitechCheatingStatusMutation();
	const [loadingButtonId, setLoadingButtonId] = useState(null);
	if (areRequestEnabled && !isFetchingNextPage && hasNextPage) {
		fetchNextPage();
	}
	const requestData = data?.pages.flatMap((page) => page.data.results) ?? [];

	useEffect(() => {
		if (!error) return;
		toast.error(
			`Tepitech alerts Data Table: ${t("An error has occured")}: ${
				error.message
			}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error]);

	function cheatedValue(cheated) {
		if (cheated === null) return 0;
		return cheated ? 2 : 1;
	}

	const exportConfig = {
		login: "student.login",
		promotion: "student.promo.promotion_year",
		city: "student.promo.city.header",
		date: (row) =>
			`${DateTime.fromISO(row.old_date).toLocaleString()} ⇒ ${DateTime.fromISO(row.new_date).toLocaleString()}`,
		score: (row) => `${row.old_score} ⇒${row.new_score}`,
	};

	const handleCheatingStatusChange = (entry, value) => {
		setLoadingButtonId(`${entry.id}-${value}`);
		cheatingMutation.mutate(
			{
				id: entry.id,
				cheated: value,
			},
			{
				onSettled: () => {
					setLoadingButtonId(null);
				},
			}
		);
	};

	const columns = [
		{
			accessorKey: "student.login",
			header: `${t("login").capfirst()}`,
			Cell: ({ row }) => (
				<LoginRow
					login={row.original.student.login}
					pageTooltip="tepitech-cheaters-tooltip"
				/>
			),
			size: 250,
		},
		{
			accessorKey: "student.promo.promotion_year",
			header: t("promotion").capfirst(),
			size: 150,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "student.promo.city.name",
			header: t("city").capfirst(),
			size: 150,
			enableGlobalFilter: false,
		},
		{
			accessorFn: (row) =>
				`${DateTime.fromISO(row.old_date).toLocaleString()} ⇒ ${DateTime.fromISO(row.new_date).toLocaleString()}`,
			header: `${t("date").capfirst()}`,
			size: 200,
			enableGlobalFilter: false,
		},
		{
			accessorFn: (row) => `${row.old_score} ⇒ ${row.new_score}`,
			header: `${t("score").capfirst()}`,
			size: 125,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "cheated",
			header: `${t("actions").capfirst()}`,
			size: 200,
			sortingFn: (a, b) => {
				const valueA = cheatedValue(a.original.cheated);
				const valueB = cheatedValue(b.original.cheated);
				return valueA - valueB;
			},
			Cell: ({ row }) => (
				<CheaterButtons
					onClick={handleCheatingStatusChange}
					is_cheater={row.original.cheated}
					loadingButtonId={loadingButtonId}
					entry={row.original}
				/>
			),
			enableGlobalFilter: false,
		},
	];

	return (
		<CommonDatatableContainer
			data={requestData}
			error={error}
			columns={columns}
			exportConfig={{
				columns: exportConfig,
				title: "tepitech_alerts_cheaters",
			}}
			isLoading={isLoading}
			isFetching={isFetching}
		/>
	);
}
