import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { t } from "i18next";
import { useContext } from "react";
import { toast } from "react-toastify";
import { ScraperApiHelperContext } from "../../contexts";
import { getNextPageParam } from "../Utils/Utils";

export const useCitiesQuery = (enabled) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["tepitechalerts", "filters_options", "cities"],
		queryFn: () => scraper.cities.getCities(),
		enabled,
		select: (data) =>
			data.map((current) => ({
				label: current.name,
				value: current.code,
			})),
	});
};

export const useCurriculumsQuery = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["tepitechalerts", "filters_options", "curriculums"],
		queryFn: () => scraper.getPromotions("bachelor"),
		select: (data) => data.toFilterOptions(),
	});
};

export const useTepitechAlertsStatsQuery = (
	cities,
	promotions,
	curriculums
) => {
	const scraper = useContext(ScraperApiHelperContext);
	const paramsCities = [];
	for (let idx = 0; idx < cities.length; idx++) {
		paramsCities.push(cities[idx].value);
	}
	return useQuery({
		queryKey: [
			"tepitechalerts",
			"stats",
			"tepitech_alerts_stats",
			paramsCities,
			promotions,
			curriculums,
		],
		queryFn: () =>
			scraper.english.getTepitechAlertsStats(
				paramsCities,
				promotions,
				curriculums
			),
	});
};

export const useUnmarkedTepitechCheating = (
	cities,
	curriculums,
	promotions
) => {
	const paramsCities = [];
	for (let idx = 0; idx < cities.length; idx++) {
		paramsCities.push(cities[idx].value);
	}
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: [
			"tepitechalerts",
			"cheating",
			"unmarked",
			paramsCities,
			curriculums,
			promotions,
		],
		queryFn: () =>
			scraper.cheaters.getTepitechCheatingUnmarked(
				paramsCities,
				curriculums,
				promotions
			),
		select: (data) => data.unmarked,
	});
};

export const useTepitechAlertsQuery = (
	cities,
	cursus,
	promotions,
	isRequestEnabled
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: ["tepitechalerts", "table_data", cities, cursus, promotions],
		queryFn: ({ pageParam }) =>
			scraper.students.getTepitechAlerts(
				cities,
				cursus,
				promotions,
				pageParam
			),
		initialPageParam: 0,
		getNextPageParam,
		enabled: isRequestEnabled,
	});
};

export const useTepitechCheatingQuery = (
	cities,
	cursus,
	promotions,
	isRequestEnabled
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: ["tepitechalerts", "cheating", cities, cursus, promotions],
		queryFn: ({ pageParam }) =>
			scraper.cheaters.getTepitechCheating(
				cities,
				cursus,
				promotions,
				pageParam
			),
		initialPageParam: 0,
		getNextPageParam,
		enabled: isRequestEnabled,
	});
};

export const useTepitechCheatingStatusMutation = () => {
	const queryClient = useQueryClient();
	const scraper = useContext(ScraperApiHelperContext);
	return useMutation({
		mutationFn: ({ id, cheated }) =>
			scraper.cheaters.updateTepitechCheating(id, cheated),
		onError: (error) => {
			toast.error(
				`Tepitech Alerts Cheating: ${t(
					"an error occurred when saving your edits"
				).capfirst()}: ${error}`,
				{
					theme: "colored",
					autoClose: 7500,
				}
			);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["tepitechalerts", "cheating"],
			});
		},
	});
};
