import { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Tooltip } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import { areFiltersValueDifferent } from "../Utils/Filters";
import StudentTooltip from "../Utils/StudentTooltip";
import { separateFiltersOptions } from "../Utils/Utils";
import Filters from "./Filters";
import MasterAlertsChart from "./MasterAlertsChart";
import MasterDataTableContainer from "./MasterDataTableContainer";
import MasterSuccessChart from "./MasterSuccessChart";

export default function Master() {
	const areRequestEnabled = useRef(false);
	const [activeFilters, setActiveFilters] = useState({
		semesters: [],
		promotions: [],
		referents: [],
	});

	const updateActiveFilters = (selectedFilters) => {
		if (areFiltersValueDifferent(activeFilters, selectedFilters)) {
			setActiveFilters(selectedFilters);
		}
		areRequestEnabled.current = true;
	};

	const { labels: selectedPromotions } = separateFiltersOptions(
		activeFilters.promotions
	);
	const { labels: selectedSemesters } = separateFiltersOptions(
		activeFilters.semesters
	);
	const { values: selectedReferentsValues } = separateFiltersOptions(
		activeFilters.referents
	);

	useEffect(() => {}, [activeFilters]);

	return (
		<Section>
			<SectionHeader title={t("CEI overview").capfirst()} />
			<SectionContent>
				<Container fluid>
					<Row>
						<Col lg={3}>
							<Filters onFilter={updateActiveFilters} />
						</Col>
						<Col lg={4}>
							<MasterAlertsChart
								promotions={selectedPromotions}
							/>
						</Col>
						<Col lg={5}>
							<MasterSuccessChart
								promotions={selectedPromotions}
							/>
						</Col>
						<Col lg={12} className="mt-3">
							<MasterDataTableContainer
								semesters={selectedSemesters}
								promotions={selectedPromotions}
								referents={selectedReferentsValues}
								areRequestEnabled={areRequestEnabled.current}
							/>
						</Col>
					</Row>
				</Container>
			</SectionContent>
			<Tooltip
				id="master-tooltip"
				style={{ textAlign: "center", width: 400 }}
			/>
			<StudentTooltip tooltipId="master-student-tooltip" />
		</Section>
	);
}
