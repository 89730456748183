import { useEffect } from "react";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import { AlertsDoughnut } from "../Cities/Graphs/PromotionsStatus";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer";
import { getCurrentAcademicYear } from "../Utils/Utils";
import { useStudentsAlertsStatsQuery } from "./Queries";

export default function MasterAlertsChart({ promotions }) {
	const currentYear = getCurrentAcademicYear();
	const promos =
		promotions && promotions.length > 0
			? promotions
			: [currentYear, currentYear + 1, currentYear + 2];
	const results = useStudentsAlertsStatsQuery(promos);
	const sums = {
		0: 0,
		1: 0,
		2: 0,
		3: 0,
	};
	const isFetching = results.some((query) => query.isFetching);
	const error = results.find((query) => query.error !== null);
	const requestData = results.map((query) => query.data?.data);
	for (let i = 0; i < requestData.length; i++) {
		if (requestData[i]) {
			sums[0] += requestData[i][0] ? requestData[i][0] : 0;
			sums[1] += requestData[i][1] ? requestData[i][1] : 0;
			sums[2] += requestData[i][2] ? requestData[i][2] : 0;
			sums[3] += requestData[i][3] ? requestData[i][3] : 0;
		}
	}

	useEffect(() => {
		if (!error) return;
		toast.error(
			`Bachelor Alerts Chart: ${t("An error has occured")}: ${
				error.message
			}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error]);

	return (
		<SauronChartsContainer
			title={t("students percentage").capfirst()}
			subtitle={`${t("by")} ${t("number of alerts")}`}
			isLoading={isFetching}
		>
			{sums ? <AlertsDoughnut alerts={sums} displayLegend /> : null}
		</SauronChartsContainer>
	);
}
