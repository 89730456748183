import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons/faCircleQuestion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect } from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { Box, BoxFooterButton, BoxTitle } from "../AdminLTE3/Box";
import FiltersForm from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { getCurrentAcademicYear, nValues } from "../Utils/Utils";
import { useCurriculumsQuery } from "./Queries";

export default function Filters({ onFilter }) {
	const scraper = useContext(ScraperApiHelperContext);
	const citiesOptions = scraper.userHelper.getCities();
	const { data, error } = useCurriculumsQuery();
	const { filters, setFilterOptions, setSelectedOption, applyFilters } =
		useFilters({
			scholar_year: {
				label: t("scholar_year"),
				type: "select",
				multiple: false,
				options: nValues(
					getCurrentAcademicYear() - 7,
					8
				).toFilterOptions(),
				default: {
					value: getCurrentAcademicYear(),
					label: getCurrentAcademicYear(),
				},
			},
			city: {
				label: t("city"),
				type: "select",
				multiple: false,
				options: citiesOptions,
				default: citiesOptions.length === 1 ? citiesOptions[0] : null,
			},
			curriculum: {
				label: t("curriculum"),
				type: "select",
				multiple: false,
				options: [],
				default: null,
			},
			promotion: {
				label: t("promotion"),
				type: "select",
				multiple: false,
				options: nValues(
					getCurrentAcademicYear() + 3,
					3
				).toFilterOptions(),
				default: null,
			},
			projection: {
				label: (
					<div
						data-tooltip-content={t(
							"projection_description"
						).capfirst()}
						data-tooltip-id="bachelor-tooltip"
					>
						{t("use projection").capfirst()}
						<FontAwesomeIcon
							icon={faCircleQuestion}
							className="ml-2"
							style={{ opacity: 0.5 }}
						/>
					</div>
				),
				type: "bool",
				default: false,
			},
		});

	useEffect(() => {
		if (!data) return;
		setFilterOptions("curriculum", data);
	}, [data]);

	useEffect(() => {
		if (!error) return;
		toast.error(
			`Bachelor Filters: ${t("An error has occured")}: ${error.message}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error]);

	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("filters").capfirst()} />
			</Card.Header>
			<Card.Body>
				<FiltersForm filters={filters} onChange={setSelectedOption} />
			</Card.Body>
			<Card.Footer>
				<BoxFooterButton
					label={t("filter").capfirst()}
					onClick={() => applyFilters(onFilter)}
				/>
			</Card.Footer>
		</Box>
	);
}
