import {
	Flex,
	Text,
	rem,
	ActionIcon,
	useMantineColorScheme,
	Menu,
	UnstyledButton,
	Group,
	TextInput,
	Switch,
	Box,
	Header,
} from "@mantine/core";
import { t } from "../../Config/i18n.jsx";
import { useEffect, useState } from "react";
import {
	IconSun,
	IconMoon,
	IconChevronDown,
	IconLogout,
	IconConfetti,
	IconZoom,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import EpitechAvatar from "../Utils/EpitechAvatar.jsx";
import { ScraperApiHelperContext } from "../../contexts";
import StudentSearchModal from "../AdminLTE3/StudentSearchModal.jsx";
import { SauronRoutes } from "../../Config/Routes.jsx";
import { useSearchStudents } from "../AdminLTE3/Queries.jsx";

const SauronNavbar = () => {
	const navigate = useNavigate();
	const [search, setSearch] = useState("");
	const [submittedSearch, setSubmittedSearch] = useState(null);
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const [, setUserMenuOpened] = useState(false);
	const [students, setStudents] = useState(null);
	const [studentModalOpened, setStudentModalOpened] = useState(false);
	const { data } = useSearchStudents(submittedSearch);
	const isCelebrationMode = localStorage.getItem("disableCelebration");
	const [celebretionMode, setCelebretionMode] = useState(
		isCelebrationMode === "true"
	);

	const onSearchChange = (event) => {
		setSearch(event.target.value);
	};

	const onSubmit = (event) => {
		if (event.key === "Enter" && search.length > 0) {
			event.preventDefault();
			setSubmittedSearch(search);
			setSearch("");
		}
	};

	useEffect(() => {
		if (data) {
			if (data.results.length === 1)
				navigate(
					SauronRoutes.student.path.replace(
						":login",
						data.results[0].login
					)
				);
			else {
				setStudents(data.results);
				setStudentModalOpened(true);
			}
		}
		setSubmittedSearch(null);
	}, [data]);

	return (
		<Header ml={300}>
			<Flex
				mih={60}
				justify="space-between"
				align="center"
				direction="row"
				wrap="nowrap"
			>
				<ScraperApiHelperContext.Consumer>
					{(scraper) => {
						if (scraper.api.getSauronUserInfo().roles.length === 0)
							return null;
						return (
							<Box ml="xs" sx={{ width: "70rem" }}>
								<TextInput
									placeholder={t(
										"search for a student"
									).capfirst()}
									style={{ fontSize: "15px" }}
									onChange={onSearchChange}
									value={search}
									onKeyDown={onSubmit}
									radius="md"
									width={500}
									icon={
										<IconZoom size="1.5rem" stroke={1.5} />
									}
								/>
							</Box>
						);
					}}
				</ScraperApiHelperContext.Consumer>
				<Flex
					mih={60}
					gap="lg"
					align="center"
					justify="space-between"
					direction="row"
					wrap="nowrap"
					mr="md"
				>
					<Menu
						width={260}
						onClose={() => setUserMenuOpened(false)}
						onOpen={() => setUserMenuOpened(true)}
						withinPortal
						ml={"5rem"}
					>
						<Menu.Target>
							<UnstyledButton>
								<ScraperApiHelperContext.Consumer>
									{(scraper) => {
										const msal_account =
											scraper.api.getMicrosoftAccountInfo();
										return (
											<Group spacing="xs">
												<EpitechAvatar
													login={
														msal_account
															? msal_account
																	.idTokenClaims
																	.email
															: ""
													}
													name={
														msal_account
															? msal_account.name.title()
															: null
													}
												/>
												<Text fz="sm" fw={700}>
													{msal_account
														? msal_account.name.title()
														: null}
												</Text>
												<IconChevronDown
													style={{
														width: rem(12),
														height: rem(12),
													}}
													stroke={1.5}
												/>
											</Group>
										);
									}}
								</ScraperApiHelperContext.Consumer>
							</UnstyledButton>
						</Menu.Target>
						<Menu.Dropdown>
							<ScraperApiHelperContext.Consumer>
								{(scraper) => {
									const msal_account =
										scraper.api.getMicrosoftAccountInfo();
									return (
										<Menu.Label>
											{msal_account
												? msal_account.idTokenClaims
														.email
												: ""}
										</Menu.Label>
									);
								}}
							</ScraperApiHelperContext.Consumer>
							<Menu.Item
								icon={<IconConfetti />}
								closeMenuOnClick={false}
								rightSection={
									<Switch
										checked={celebretionMode}
										onChange={(event) =>
											setCelebretionMode(
												event.currentTarget.checked
											)
										}
									/>
								}
							>
								{t("Festive theme")}
							</Menu.Item>
							<Menu.Item
								onClick={() => navigate("logout")}
								color="red"
								icon={<IconLogout size={14} />}
							>
								{t("logout").capfirst()}
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
					<ActionIcon
						size="l"
						radius="md"
						variant="outlined"
						onClick={() => toggleColorScheme()}
					>
						{colorScheme === "dark" ? (
							<IconSun
								style={{ width: rem(30), height: rem(30) }}
								stroke={1.5}
							/>
						) : (
							<IconMoon
								style={{ width: rem(30), height: rem(30) }}
								stroke={1.5}
							/>
						)}
					</ActionIcon>
				</Flex>
				<StudentSearchModal
					students={students}
					opened={studentModalOpened}
					onClose={() => setStudentModalOpened(false)}
				/>
			</Flex>
		</Header>
	);
};

export default SauronNavbar;
