import { t } from "../../Config/i18n";
import SauronMantineDataTable from "../Sauron/SauronMantineDataTable";
import LoginRow from "../Utils/LoginRow";
import MissingTranscriptsSpan from "./Columns/MissingTranscriptsSpan";
import Semester from "./Columns/Semester";

export default function TranscriptDataTable({
	data,
	exportConfig,
	isLoading,
	isFetching,
}) {
	const columns = [
		{
			accessorKey: "nb_missing",
			header: t("number of missing transcripts").capfirst(),
			Cell: ({ row }) => (
				<MissingTranscriptsSpan nbMissing={row.original.nb_missing} />
			),
			size: 125,
			invertSorting: true,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "login",
			header: t("login").capfirst(),
			invertSorting: true,
			Cell: ({ row }) => (
				<LoginRow
					login={row.original.login}
					pageTooltip="bachelor-student-tooltip"
				/>
			),
		},
		{
			accessorKey: "city",
			header: t("city").capfirst(),
			size: 100,
			invertSorting: true,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "promotion",
			header: t("promotion").capfirst(),
			size: 100,
			invertSorting: true,
			enableGlobalFilter: false,
		},
	];
	for (let semesterNumber = 1; semesterNumber < 11; semesterNumber++) {
		columns.push({
			id: `semester_${semesterNumber}`,
			accessorFn: (row) => row.transcripts[`Semester ${semesterNumber}`],
			header: `${t("semester").capfirst()} ${semesterNumber}`,
			minSize: 100,
			enableSorting: false,
			Cell: ({ row }) => (
				<Semester
					semester={
						row.original.transcripts[`Semester ${semesterNumber}`]
					}
				/>
			),
			enableGlobalFilter: false,
		});
		if (semesterNumber === 6) {
			columns.push({
				accessorKey: "transcripts.Bachelor",
				header: t("bachelor").capfirst(),
				minSize: 100,
				enableSorting: false,
				Cell: ({ row }) => (
					<Semester
						semester={row.original.transcripts.Bachelor}
						login={row.original.login}
					/>
				),
				enableGlobalFilter: false,
			});
		}
	}
	columns.push({
		accessorKey: "transcripts.Master",
		header: t("master").capfirst(),
		minSize: 100,
		enableSorting: false,
		Cell: ({ row }) => (
			<Semester
				semester={row.original.transcripts.Master}
				login={row.original.login}
			/>
		),
		enableGlobalFilter: false,
	});
	return (
		<SauronMantineDataTable
			title={t("transcripts").capfirst()}
			subtitle={t("per students")}
			columns={columns}
			data={data}
			exportConfig={exportConfig}
			isLoading={isLoading}
			isFetching={isFetching}
			isCard
		/>
	);
}
