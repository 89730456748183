import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { ScraperApiHelperContext } from "../../contexts";
import {
	getCurrentAcademicYear,
	getNextPageParam,
	promotionYearToBachelorPromotion,
} from "../Utils/Utils";

const year = getCurrentAcademicYear();

export const useInvestmentInfiniteQuery = (
	city,
	promotion,
	begin,
	end,
	alert
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: [
			"investment",
			"table_data",
			"investments",
			city,
			promotion,
			begin,
			end,
			alert,
		],
		queryFn: ({ pageParam }) =>
			scraper.students.getInvestment(
				pageParam,
				city,
				promotion,
				begin,
				end,
				alert
			),
		initialPageParam: 0,
		getNextPageParam,
		enabled: !!city && !!promotion,
		select: (response) => {
			const ret = { ...response };
			ret.pages = response.pages.map((page) =>
				page.data.results.map((result) => ({
					student: result.student,
					login: result.student.login,
					promotion: promotionYearToBachelorPromotion(
						result.student.promo.promotion_year,
						getCurrentAcademicYear()
					),
					curriculum: `${result.student.promo.cursus.code}/${result.student.promo.subpromo}`,
					city: result.student.promo.city.name,
					logtime_count: result.logtime_count,
					logtime_ratio: result.logtime_ratio,
					presences_count: result.presences_count,
					presences_ratio: result.presences_ratio,
					commits_count: result.commits_count,
					commits_ratio: result.commits_ratio,
					lines_count: result.lines_count,
					lines_ratio: result.lines_ratio,
					score: result.score,
					alert: result.alert,
					stats: result.stats,
				}))
			);
			return ret;
		},
	});
};

export const useInvestmentProgressionStatsQuery = (
	cities,
	promotions,
	national
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["investment", "progression", cities, promotions, national],
		queryFn: () =>
			scraper.students.getInvestmentProgressionStats(
				[year],
				cities,
				promotions,
				national
			),
		select: (response) => response.data,
	});
};

export const useInvestmentStudentQuery = (login) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["investment", "student", login],
		queryFn: () => scraper.student.getInvestment(login),
		select: (response) => response.data?.results[0],
		enabled: !!login,
	});
};

export const useInvestmentAlertsQuery = (city, promotion) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: ["investment", "alerts", city, promotion],
		queryFn: () => scraper.students.getInvestmentAlerts(city, promotion),
		initialPageParam: 0,
		getNextPageParam,
	});
};
