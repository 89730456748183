import { t } from "i18next";
import { DateTime } from "luxon";
import { Card } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import PerfectScrollbar from "react-perfect-scrollbar";
import { BoxTitle } from "../AdminLTE3/Box";
import { GRAPH_LINE_COLORS } from "../Sauron/Graphs/GraphColors";
import SauronMantineDataTable from "../Sauron/SauronMantineDataTable";
import LoginRow from "../Utils/LoginRow";
import NoDataAvailable from "../Utils/NoDataAvailable";

const options = {
	maintainAspectRatio: false,
	scales: {
		y: {
			stacked: false,
			min: 0,
			ticks: {
				precision: 0,
			},
		},
	},
	tooltips: {
		callbacks: {
			label: (item, ctx) =>
				`${ctx.datasets[item.datasetIndex].label}: ${item.yLabel}`,
		},
	},
};

function GroupCommitsOverview({ data: groupStats }) {
	if (!groupStats || Object.keys(groupStats).length === 0)
		return <NoDataAvailable />;
	const labels = Object.values(groupStats)[0].weeks.map((entry) =>
		DateTime.fromISO(entry.week).toLocaleString({
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		})
	);
	const graphLinesData = {
		labels,
		datasets: Object.keys(groupStats).map((login, index) => ({
			label: login,
			data: groupStats[login].weeks.map((entry) => entry.c),
			backgroundColor: `${GRAPH_LINE_COLORS[index]}, 0.1)`,
			borderColor: GRAPH_LINE_COLORS[index],
			fill: true,
			tension: 0.3,
		})),
	};

	return (
		<div style={{ height: "300px" }}>
			{" "}
			<Line data={graphLinesData} options={options} />
		</div>
	);
}

export default function ProjectExpandableComponent({
	data: entry,
	isExpanded,
}) {
	if (!isExpanded) return null;

	const columns = [
		{
			id: "login",
			accessorFn: (row) => row.login,
			header: t("student").capfirst(),
			Cell: ({ row }) => (
				<LoginRow
					login={row.original.login}
					pageTooltip="projects-student-tooltip"
				/>
			),
		},
		{
			id: "commits",
			accessorFn: (row) => row.c || 0,
			header: t("commits").capfirst(),
		},
		{
			id: "additions",
			accessorFn: (row) => row.a || 0,
			Cell: ({ row }) => (
				<span className="text-success">{row.original.a || 0}</span>
			),
			header: t("lines added").capfirst(),
		},
		{
			id: "deletions",
			accessorFn: (row) => row.d || 0,
			Cell: ({ row }) => (
				<span className="text-danger">{row.original.d || 0}</span>
			),
			header: t("lines deleted").capfirst(),
		},
		{
			id: "lines_of_code",
			accessorFn: (row) => (row.a || 0) - (row.d || 0),
			header: t("lines of code").capfirst(),
		},
	];
	return (
		<PerfectScrollbar style={{ padding: "15px 15px" }}>
			<SauronMantineDataTable
				data={entry.group.members.map((member) => ({
					...entry.stats[member.login],
					login: member.login,
				}))}
				columns={columns}
				tableProps={{
					renderTopToolbar: false,
					renderBottomToolbar: false,
				}}
			></SauronMantineDataTable>
			<Card className="mt-4">
				<Card.Header>
					<BoxTitle title={t("group commits overview").capfirst()} />
				</Card.Header>
				<Card.Body>
					<GroupCommitsOverview data={entry.stats} />
				</Card.Body>
			</Card>
		</PerfectScrollbar>
	);
}
