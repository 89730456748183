import ScraperAPI from "./Scraper";
import UserHelper from "./UserHelper";
import Activity from "./resources/Activity";
import Cheaters from "./resources/Cheaters";
import Cities from "./resources/Cities";
import Config from "./resources/Config";
import Diversity from "./resources/Diversity";
import English from "./resources/English";
import GradingSkills from "./resources/GradingSkills";
import Instance from "./resources/Instance";
import Internships from "./resources/Internships";
import Module from "./resources/Module";
import ProfessionalCommunication from "./resources/ProfessionalCommunication";
import Projects from "./resources/Projects";
import Roadblocks from "./resources/Roadblocks";
import ScraperInfos from "./resources/ScraperInfos";
import Student from "./resources/Student";
import Students from "./resources/Students";
import Tesseract from "./resources/Tesseract";
import Token from "./resources/Token";
import Unlabeled from "./resources/Unlabeled";

export default class ScraperAPIHelper {
	constructor() {
		this.api = new ScraperAPI();
		this.userHelper = new UserHelper(this.api);
		this.activity = new Activity(this.api);
		this.cheaters = new Cheaters(this.api);
		this.config = new Config(this.api);
		this.english = new English(this.api);
		this.grading_skills = new GradingSkills(this.api);
		this.diversity = new Diversity(this.api);
		this.instance = new Instance(this.api);
		this.internships = new Internships(this.api);
		this.module = new Module(this.api);
		this.professional_communication = new ProfessionalCommunication(
			this.api
		);
		this.projects = new Projects(this.api);
		this.roadblocks = new Roadblocks(this.api);
		this.student = new Student(this.api);
		this.students = new Students(this.api);
		this.tesseract = new Tesseract(this.api);
		this.token = new Token(this.api);
		this.unlabeled = new Unlabeled(this.api);
		this.cities = new Cities(this.api);
		this.scraperInfos = new ScraperInfos(this.api);
	}

	getPromotions(cursus) {
		return this.api.processRequest("GET", "/promotions").then((res) => {
			const curriculums = new Set();
			res.data.forEach((promo) => {
				if (cursus && promo.cursus.code != cursus) return;
				curriculums.add(
					promo.cursus.code +
						(promo.subpromo.length > 0 ? `/${promo.subpromo}` : "")
				);
			});
			return Array.from(curriculums).sort();
		});
	}

	getDiversityStats(
		year = null,
		city = null,
		curriculums = null,
		promotions = null
	) {
		const params = {
			city,
			year,
			curriculums,
			promotions,
		};
		return this.api.processRequest("GET", "/diversity/stats", { params });
	}

	getMasterSemesters(curriculums = ["master/classic", "master/tek3si"]) {
		return this.api
			.processRequest("GET", "/semesters", {
				params: { curriculum: curriculums },
			})
			.then((res) => res.data);
	}

	getReferents() {
		return this.api
			.processRequest("GET", "/eip/referents")
			.then((res) => res.data);
	}

	createStudentsReports(value, header) {
		return this.api.processRequest("POST", `/investment_reports`, {
			headers: header,
			data: value,
		});
	}

	downloadStudentReports(hash, header) {
		return this.api.processRequest(
			"GET",
			"/investment_reports",
			{ headers: header, params: { hash } },
			null,
			"blob"
		);
	}
}
