import CobWeb from "../../Assets/cobweb.png";
import Ghost from "../../Assets/halloween_cursor.gif";
import HalloweenHatImg from "../../Assets/halloween_hat.png";

export function HalloweenCursor() {
	if (
		import.meta.env.VITE_CELEBRATION !== "halloween" ||
		localStorage.getItem("disableCelebration") === "true"
	)
		return null;
	return <img className="HalloweenMouse" src={Ghost} alt="cursor" />;
}

export function HalloweenFooter() {
	if (
		import.meta.env.VITE_CELEBRATION !== "halloween" ||
		localStorage.getItem("disableCelebration") === "true"
	)
		return null;
	return <img className="HalloweenFooter" src={CobWeb} alt="Cobweb" />;
}

export function HalloweenHat({ avatar }) {
	if (
		avatar === "profile" &&
		import.meta.env.VITE_CELEBRATION === "halloween" &&
		localStorage.getItem("disableCelebration") === "false"
	)
		return (
			<img
				className="HalloweenHat"
				src={HalloweenHatImg}
				alt="Halloween Hat"
			/>
		);
	return null;
}
