import { cacheDisabler } from "../../Components/Utils/Utils";

export default class Token {
	constructor(api) {
		this.api = api;
	}

	keyGen = (id, groups, modules_responsible, lifespan = true, city = null) =>
		this.api.processRequest("POST", "/admin/token", {
			params: {
				id,
				groups,
				modules_responsible,
				lifespan,
				city,
				...cacheDisabler(),
			},
		});

	getAccessTokens = () =>
		this.api.processRequest("GET", "/admin/token/access", {
			params: cacheDisabler(),
		});

	revokeAccessToken = (token) =>
		this.api.processRequest("DELETE", "/admin/token/access", {
			data: { token },
		});

	clearExpiredAccessTokens = () =>
		this.api.processRequest("DELETE", "/admin/token/access/expired");

	getAPITokens = () =>
		this.api.processRequest("GET", "/admin/token/api", {
			params: cacheDisabler(),
		});

	revokeAPIToken = (token) =>
		this.api.processRequest("DELETE", "/admin/token/api", {
			data: { token },
		});

	getRefreshTokens = () =>
		this.api.processRequest("GET", "/admin/token/refresh", {
			params: cacheDisabler(),
		});

	revokeRefreshToken = (token) =>
		this.api.processRequest("DELETE", "/admin/token/refresh", {
			data: { token },
		});

	clearExpiredRefreshTokens = () =>
		this.api.processRequest("DELETE", "/admin/token/refresh/expired");

	getAvailableRoles = () =>
		this.api.processRequest("GET", "/admin/available_groups");
}
