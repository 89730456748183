import "bootstrap";
import "core-js/stable";
import "regenerator-runtime/runtime";

import { MsalProvider } from "@azure/msal-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Chart, Filler, registerables } from "chart.js/auto";
import annotationPlugin from "chartjs-plugin-annotation";
import * as ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import ScraperAPIHelper from "./API/ScraperAPIHelper";
import { ChristmasSnowFlake } from "./Components/Celebration/Christmas";
import FakeOauthHandler from "./Components/Oauth2/FakeOauth2";
import OauthHandler from "./Components/Oauth2/Oauth2";
import msalInstance from "./Components/Oauth2/msalInstance";
import { Config } from "./Config/Config";
import { GlobalTranslation } from "./Config/i18n";
import { ScraperApiHelperContext } from "./contexts";
Chart.register(
	...registerables,
	ChartjsPluginStacked100.default,
	Filler,
	annotationPlugin
);
const client = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});
const celebration =
	localStorage.getItem("disableCelebration") === "false"
		? import.meta.env.VITE_CELEBRATION || "override"
		: "override";
import("../styles/override.scss");
switch (celebration) {
	case "halloween":
		import("../styles/halloween.scss");
		break;
	case "christmas":
		import("../styles/christmas.scss");
		break;
	case "easter":
		import("../styles/easter.scss");
		break;
	default:
		import("../styles/default.scss");
}

function getOauthHandler() {
	if (Config.dev_token) return <FakeOauthHandler />;
	return (
		<MsalProvider instance={msalInstance}>
			<OauthHandler />
		</MsalProvider>
	);
}

const container = document.getElementById("app");
const root = createRoot(container);
root.render(
	<StrictMode>
		<QueryClientProvider client={client}>
			<ToastContainer />
			<GlobalTranslation />
			<ScraperApiHelperContext.Provider value={new ScraperAPIHelper()}>
				{getOauthHandler()}
			</ScraperApiHelperContext.Provider>
			<ChristmasSnowFlake />
		</QueryClientProvider>
	</StrictMode>
);
