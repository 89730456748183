import { useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer";
import { defaultBarOptions } from "../Utils/DefaultConst";
import { formatDataForSuccessCharts } from "../Utils/FormatDataForCharts";
import { getAlertLabels } from "../Utils/Labels";
import { useRoadblocksAlertsStatsQuery } from "./Queries";

export default function BachelorSuccessChart({
	city,
	curriculum,
	promotion,
	projection,
}) {
	const alertLabels = getAlertLabels(curriculum);
	const { isFetching, error, data } = useRoadblocksAlertsStatsQuery(
		city?.value,
		promotion,
		curriculum,
		projection
	);
	let func;
	if (data?.data && Object.keys(data.data).length > 0) {
		// eslint-disable-next-line no-unused-vars
		const { Global, Europe, ...filtered } = data.data;
		func = formatDataForSuccessCharts(filtered, alertLabels, city);
	} else {
		func = formatDataForSuccessCharts(undefined, alertLabels, city);
	}
	const successData = useMemo(() => func, [data]);

	useEffect(() => {
		if (!error) return;
		toast.error(
			`Bachelor Success Chart: ${t("An error has occured")}: ${
				error.message
			}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error]);

	return (
		<SauronChartsContainer
			title={t("success rate").capfirst()}
			subtitle={`${t("by")} ${t("roadblocks")}`}
			isLoading={isFetching}
			height={410}
		>
			{successData ? (
				<Bar data={successData} options={defaultBarOptions} />
			) : null}
		</SauronChartsContainer>
	);
}
