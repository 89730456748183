import { Button, Card } from "react-bootstrap";
import { Tooltip } from "react-tooltip";

import "admin-lte/dist/css/adminlte.min.css";
import "admin-lte/dist/js/adminlte";

export function Box({ type = "primary", className = "", ...props }) {
	return (
		<Card className={`card-${type} card-outline ${className}`} {...props}>
			{props.children}
		</Card>
	);
}

export function BoxTitle({
	className = "",
	title = "",
	subtitle = "",
	children,
	...props
}) {
	return (
		<h3 className={`card-title ${className}`} {...props}>
			{title}
			<small> {subtitle}</small>
			{children}
		</h3>
	);
}

export function BoxFooterButton({
	label = "",
	onClick,
	variant = "primary",
	...props
}) {
	return (
		<Button
			type="button"
			variant={variant}
			block
			onClick={onClick}
			{...props}
		>
			{label}
		</Button>
	);
}

export function BoxTitleButton({ onClick, tooltip = "", label = "" }) {
	return (
		<Button
			type="button"
			variant="primary"
			className="btn-xs float-right"
			style={{
				width: "50%",
				maxWidth: 300,
				paddingTop: 0,
				paddingBottom: 0,
			}}
			onClick={onClick}
			data-place="bottom"
			data-tooltip-content={tooltip}
			data-tooltip-id="box-button-tooltip"
			data-multiline
		>
			{label}
			<Tooltip id="box-button-tooltip" />
		</Button>
	);
}
