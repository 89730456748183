import { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { t } from "../../Config/i18n";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import { areFiltersValueDifferent } from "../Utils/Filters";
import StudentTooltip from "../Utils/StudentTooltip";
import { getCurrentAcademicYear } from "../Utils/Utils.jsx";
import BachelorAlertsChart from "./BachelorAlertsChart";
import BachelorDataTableContainer from "./BachelorDataTableContainer";
import BachelorSuccessChart from "./BachelorSuccessChart";
import Filters from "./Filters";

export default function Bachelor() {
	const areRequestEnabled = useRef(false);
	const [activeFilters, setActiveFilters] = useState({
		city: null,
		curriculum: null,
		promotion: null,
		projection: false,
		scholar_year: getCurrentAcademicYear(),
	});

	const promotion = activeFilters.promotion
		? activeFilters.promotion.value
		: null;
	const curriculum = activeFilters.curriculum
		? activeFilters.curriculum.value
		: "bachelor";

	const updateActiveFilters = (selectedFilters) => {
		if (areFiltersValueDifferent(activeFilters, selectedFilters))
			setActiveFilters(selectedFilters);
		areRequestEnabled.current = true;
	};

	return (
		<Section>
			<SectionHeader title={t("bachelor overview").capfirst()} />
			<SectionContent>
				<Container fluid>
					<Row>
						<Col lg={3} className="mb-3">
							<Filters onFilter={updateActiveFilters} />
						</Col>
						<Col lg={4} className="mb-3">
							<BachelorAlertsChart
								cities={activeFilters.city}
								curriculum={curriculum}
								promotion={promotion}
								projection={activeFilters.projection}
							/>
						</Col>
						<Col lg={5} className="mb-3">
							<BachelorSuccessChart
								city={activeFilters.city}
								curriculum={curriculum}
								promotion={promotion}
								projection={activeFilters.projection}
							/>
						</Col>
						<Col lg={12}>
							<BachelorDataTableContainer
								city={activeFilters.city}
								scholar_year={activeFilters.scholar_year}
								curriculum={curriculum}
								promotion={promotion}
								areRequestEnabled={areRequestEnabled.current}
								projection={activeFilters.projection}
							/>
						</Col>
					</Row>
				</Container>
			</SectionContent>
			<Tooltip
				id="bachelor-tooltip"
				style={{ textAlign: "center", width: 400 }}
			/>
			<StudentTooltip tooltipId="bachelor-student-tooltip" />
		</Section>
	);
}
