import { ActionIcon, Group, List, Modal, Text, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconInfoSmall } from "@tabler/icons-react";
import { t } from "../../Config/i18n";
import { getCurrentAcademicYear } from "../Utils/Utils";

export default function ModuleCollisionModal({ units }) {
	const [opened, { open, close }] = useDisclosure(false);
	const unitsLength = units?.length;
	const currentYear = getCurrentAcademicYear();

	return (
		<Group>
			{unitsLength === 0 ? (
				<Text>0</Text>
			) : (
				((
					<Group>
						<Modal
							opened={opened}
							onClose={close}
							title={t("module_collision_modal_title", {
								unitsLength,
							})}
							centered
							size="auto"
						>
							{units?.map((unit, index) => {
								const periodIndex = index + 1;
								return (
									<div
										style={{ marginBottom: "10px" }}
										key={index}
									>
										<Text key={index} fw={700}>
											{t("period", { periodIndex })}
										</Text>
										{unit.map((module, i) => (
											<Group key={i}>
												<List>
													<List.Item>
														<a
															href={`https://intra.epitech.eu/module/${currentYear}/${module}`}
															target="_blank"
															rel="noreferrer"
														>
															{module}
														</a>
													</List.Item>
												</List>
											</Group>
										))}
									</div>
								);
							})}
						</Modal>
						<Text c="red">{unitsLength}</Text>
						<Tooltip label={t("more details")} withArrow>
							<ActionIcon
								size="1.3em"
								color="blue"
								variant="filled"
								onClick={open}
							>
								<IconInfoSmall size={24} />
							</ActionIcon>
						</Tooltip>
					</Group>
				) ?? <Text>N/A</Text>)
			)}
		</Group>
	);
}
