import { t } from "../../Config/i18n";
import LoginRow from "../Utils/LoginRow";
import { compare } from "../Utils/Utils";
import CommonDatatableContainer from "./CommonDatatableContainer";
import { ALERTS, ALERT_LABELS, internshipAlert } from "./InternshipAlertUtils";
import { useContracts } from "./Queries";

export default function ContractDatatableContainer({
	cities,
	curriculums,
	promotions,
	full_time,
	alertsOnly,
	areRequestEnabled,
}) {
	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		error,
		isLoading,
		isFetching,
	} = useContracts(
		cities.extract("value"),
		curriculums,
		promotions,
		full_time,
		areRequestEnabled
	);

	const reqData = data?.pages.flatMap((page) => page.data.results) ?? [];

	const filteredRequestData = reqData.filter((row) => {
		if (alertsOnly && (row.status === "confirmed" || row.status === "")) {
			return false;
		}
		return true;
	});

	if (areRequestEnabled && !isFetchingNextPage && hasNextPage)
		fetchNextPage();

	const contract_columns = [
		{
			accessorKey: "alert",
			header: t("alert").capfirst(),
			Cell: ({ row }) => {
				const spec = ALERT_LABELS[row.original.status];
				return internshipAlert(spec, row.original.student.login);
			},
			sortingFn: (a, b) =>
				-compare(
					ALERTS.indexOf(b.original.status),
					ALERTS.indexOf(a.original.status)
				),
			size: 250,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "student.login",
			header: t("login").capfirst(),
			Cell: ({ row }) => (
				<LoginRow
					login={row.original.student.login}
					pageTooltip="internship-parttime-student-tooltip"
				/>
			),
			size: 200,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "student.promo.promotion_year",
			header: t("promotion").capfirst(),
			size: 75,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "curriculum",
			header: t("curriculum").capfirst(),
			Cell: ({ row }) => {
				if (row.original.student.promo.subpromo)
					return `${row.original.student.promo.cursus.code}/${row.original.student.promo.subpromo}`;
				return row.original.student.promo.cursus.code;
			},
			size: 150,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "student.promo.city.name",
			header: t("city").capfirst(),
			size: 100,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "master_email",
			header: t("master").capfirst(),
			size: 200,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "tutor_email",
			header: t("internship tutor").capfirst(),
			size: 200,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "contract_type",
			header: t("contract").capfirst(),
			size: 100,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "begin",
			header: t("begin").capfirst(),
			size: 100,
			sortingFn: (a, b) => compare(a.original.begin, b.original.begin),
			enableGlobalFilter: false,
		},
		{
			accessorKey: "end",
			header: t("end").capfirst(),
			size: 100,
			sortingFn: (a, b) => compare(a.original.end, b.original.end),
			enableGlobalFilter: false,
		},
		{
			accessorKey: "duration",
			header: t("duration").capfirst(),
			size: 75,
			enableGlobalFilter: false,
		},
	];

	const exportConfig = {
		"login": "student.login",
		"status": "status",
		"promotion": "student.promo.promotion_year",
		"curriculum": (row) =>
			`${row.student.promo.cursus.code}/${row.student.promo.subpromo}`,
		"city": "student.promo.city.name",
		"master email": "master_email",
		"tutor email": "tutor_email",
		"contract type": "contract_type",
		"begin date": "begin",
		"end date": "end",
		"duration": "duration",
	};

	return (
		<CommonDatatableContainer
			data={filteredRequestData}
			error={error}
			columns={contract_columns}
			exportConfig={{
				columns: exportConfig,
				title: "internship_contract_alerts",
			}}
			isLoading={isLoading}
			isFetching={isFetching}
		/>
	);
}
