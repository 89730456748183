import { Tooltip } from "@mantine/core";
import { t } from "../../Config/i18n";
import SauronMantineDataTable from "../Sauron/SauronMantineDataTable";
import LoginRow from "../Utils/LoginRow";
import getPresenceStatus from "./PresenceStatus";

export default function StudentPresenceDataTable({
	data,
	activities,
	exportConfig,
	isLoading,
	isFetching,
}) {
	const presenceStatus = getPresenceStatus();
	function StudentPresenceSpan({ entry, activity }) {
		if (!entry[activity.code_acti]) return null;
		const status = presenceStatus[entry[activity.code_acti].status];
		return <b className={status.text}>{status.label.capfirst()}</b>;
	}

	const columns = [
		{
			accessorKey: "login",
			header: "Login",
			Cell: ({ row }) => (
				<LoginRow
					login={row.original.login}
					pageTooltip="student-presence-student-tooltip"
				/>
			),
		},
		...activities.map((activity) => ({
			id: activity.code_acti,
			accessorFn: (row) => row[activity.code_acti],
			enableGlobalFilter: false,
			header: `${activity.name}`,
			Header: () => (
				<div
					style={{
						whiteSpace: "nowrap",
						overflow: "hidden",
						textOverflow: "ellipsis",
					}}
				>
					<Tooltip
						withinPortal
						multiline
						label={
							`${activity.name}\n` +
							`${t("Start date")}: ${activity.begin}\n` +
							`${t("End date")}: ${activity.end}`
						}
						sx={{ whiteSpace: "pre-wrap" }}
					>
						<span>{activity.name}</span>
					</Tooltip>
				</div>
			),
			Cell: ({ row }) => (
				<StudentPresenceSpan entry={row.original} activity={activity} />
			),
		})),
	];

	return (
		<SauronMantineDataTable
			title={t("student presences").capfirst()}
			subtitle={`${t("by")} ${t("activities")}`}
			columns={columns}
			data={data}
			exportConfig={exportConfig}
			isLoading={isLoading}
			isFetching={isFetching}
			isCard
		/>
	);
}
