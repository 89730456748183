import { useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer";
import { defaultBarOptions } from "../Utils/DefaultConst";
import { formatDataForAlertsCharts } from "../Utils/FormatDataForCharts";
import { getCurrentAcademicYear } from "../Utils/Utils";
import { useStudentsAlertsStatsQuery } from "./Queries";

export default function BachelorAlertsChart({
	cities,
	curriculum,
	promotion,
	projection,
}) {
	const requestedCities = [{ label: "France", value: null }];

	if (cities) requestedCities.push(cities);
	const currentYear = getCurrentAcademicYear();
	const promotions = promotion
		? [promotion]
		: [currentYear + 3, currentYear + 4, currentYear + 5];
	const results = useStudentsAlertsStatsQuery(
		requestedCities,
		promotions,
		curriculum,
		projection
	);
	const isFetching = results.some((query) => query.isFetching);
	const error = results.find((query) => query.error !== null);
	const requestData = results.map((query) => query.data?.data);
	const alertData = useMemo(
		() =>
			formatDataForAlertsCharts(requestData, requestedCities, promotions),
		[requestData]
	);

	useEffect(() => {
		if (!error) return;
		toast.error(
			`Bachelor Alerts Chart: ${t("An error has occured")}: ${
				error.message
			}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error]);

	return (
		<SauronChartsContainer
			title={t("students percentage").capfirst()}
			subtitle={`${t("by")} ${t("number of alerts")}`}
			isLoading={isFetching}
			height={410}
		>
			{alertData ? (
				<Bar data={alertData} options={defaultBarOptions} />
			) : null}
		</SauronChartsContainer>
	);
}
