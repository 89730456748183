import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mantine/core";
import { useColorScheme } from "@mantine/hooks";
import Difficulty from "../../Assets/difficulty.svg";
import Ghost from "../../Assets/ghost.svg";
import Medal from "../../Assets/medal.svg";
import Remarkable from "../../Assets/remarkable.svg";
import { t } from "../../Config/i18n";
import SauronMantineDataTable from "../Sauron/SauronMantineDataTable";
import LoginRow from "../Utils/LoginRow";
import { compare } from "../Utils/Utils";
import TesseractExpandableComponent from "./TesseractExpandableComponent";

const flagToImage = (login, flag) => {
	const images = {
		ghost: (
			<img
				className="littleIcon"
				src={Ghost}
				key={`${login}_ghost`}
				alt="ghost"
			/>
		),
		difficulty: (
			<img
				className="littleIcon"
				src={Difficulty}
				key={`${login}_difficulty`}
				alt="difficulty"
			/>
		),
		remarkable: (
			<img
				className="littleIcon"
				src={Remarkable}
				key={`${login}_remarkable`}
				alt="remarkable"
			/>
		),
		medal: (
			<img
				className="littleIcon"
				src={Medal}
				key={`${login}_medal`}
				alt="medal"
			/>
		),
	};
	return images[flag];
};

const FLAGS_VALUES = {
	ghost: 1,
	difficulty: 2,
	remarkable: 3,
	medal: 4,
};

const GRADE_COLORS = {
	"A": "#2ecc71",
	"B": "#2980b9",
	"C": "#f1c40f",
	"D": "#e67e22",
	"Acquis": "#33bdb6",
	"Echec": "#c0392b",
	"-": "#bdc3c7",
};

const GRADE_CHIP_STYLING = {
	border: "solid 1.5px",
	borderRadius: "16px",
	padding: "2px 10px",
	fontWeight: 700,
};
export function GradeCell({ grade, chip }) {
	const color = GRADE_COLORS[grade] ?? GRADE_COLORS["-"];
	const style = {
		color,
		...(chip ? GRADE_CHIP_STYLING : {}),
	};
	return <div style={style}>{grade}</div>;
}

export default function TesseractDataTable({
	data = [],
	showFinalCreditsColumn = false,
	toolbarInternalActionsBonus,
	preToolbarInternalActionsBonus,
	title,
	subtitle,
	isLoading,
	isFetching,
}) {
	const { colorScheme } = useColorScheme();
	const columns = [
		{
			accessorKey: "login",
			header: "Login",
			size: 250,
			Cell: ({ row }) => (
				<LoginRow
					login={row.original.login}
					pageTooltip="tesseract-student-tooltip"
				/>
			),
		},
		{
			accessorKey: "instance",
			header: "Instance",
			size: 100,
			enableGlobalFilter: false,
		},
		...(showFinalCreditsColumn
			? [
					{
						accessorKey: "credits_final",
						header: t("final credits").capfirst(),
						enableGlobalFilter: false,
					},
				]
			: []),
		{
			accessorKey: "grade_final",
			header: t("final grade").capfirst(),
			enableGlobalFilter: false,
			mantineTableBodyCellProps: { align: "center" },
			maxSize: 125,
			Cell: ({ row }) => (
				<GradeCell grade={row.original.grade_final} chip />
			),
		},
		{
			accessorKey: "grade_delivery",
			header: t("delivery grade").capfirst(),
			enableGlobalFilter: false,
			mantineTableBodyCellProps: { align: "center" },
			maxSize: 125,
			Cell: ({ row }) => (
				<GradeCell
					grade={row.original.grade_delivery}
					chip={
						row.original.grade_delivery === row.original.grade_final
					}
				/>
			),
		},
		{
			accessorKey: "grade_bttf",
			header: t("BTTF grade").capfirst(true),
			enableGlobalFilter: false,
			mantineTableBodyCellProps: { align: "center" },
			maxSize: 125,
			Cell: ({ row }) => (
				<GradeCell
					grade={row.original.grade_bttf}
					chip={
						row.original.grade_delivery !== row.original.grade_final
					}
				/>
			),
		},
		{
			accessorKey: "grade_provisional",
			enableGlobalFilter: false,
			mantineTableBodyCellProps: { align: "center" },
			maxSize: 150,
			header: t("provisional grade"),
			Header: () => (
				<Tooltip
					label={t(
						"computed from the delivery results if they exist, or the provisional (e.g.: Git mode on Jenkins) ones otherwise"
					).capfirst()}
					withinPortal
					sx={{ whiteSpace: "pre-wrap" }}
				>
					<div>
						<em>{t("provisional grade").capfirst()}</em>
						<FontAwesomeIcon
							icon={faCircleQuestion}
							className="ml-2"
							style={{ opacity: 0.5 }}
						/>
					</div>
				</Tooltip>
			),
			Cell: ({ row }) => (
				<GradeCell grade={row.original.grade_provisional} />
			),
		},
		{
			accessorKey: "flags",
			header: t("flags").capfirst(),
			enableGlobalFilter: false,
			mantineTableBodyCellProps: { align: "center" },
			maxSize: 150,
			Cell: ({ row }) =>
				row.original.flags?.map((flag) =>
					flagToImage(row.original.login, flag)
				),
			sortingFn: (a, b) =>
				-compare(
					a.original.flags.length > 0
						? FLAGS_VALUES[a.original.flags[0]]
						: 0,
					b.original.flags.length > 0
						? FLAGS_VALUES[b.original.flags[0]]
						: 0
				),
		},
	];
	return (
		<SauronMantineDataTable
			title={title}
			subtitle={subtitle}
			data={data}
			columns={columns}
			tableProps={{
				renderDetailPanel: ({ row }) => (
					<TesseractExpandableComponent
						data={row.original}
						colorScheme={colorScheme}
						isExpanded={row.getIsExpanded()}
					/>
				),
			}}
			preToolBarInternalActionsBonus={preToolbarInternalActionsBonus}
			toolbarInternalActionsBonus={toolbarInternalActionsBonus}
			isLoading={isLoading}
			isFetching={isFetching}
			isCard
		/>
	);
}
