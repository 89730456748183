import { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import InternshipProgress from "../Cities/Graphs/InternshipProgress";
import { areFiltersValueDifferent } from "../Utils/Filters";
import { getPGEPromotionOptions } from "../Utils/Utils";
import AlertCards from "./AlertCards";
import PromotionStatus from "./Cards/PromotionStatus";
import Filters, { getPossibleViewOptions } from "./Filters";

export default function Dashboard() {
	const scraper = useContext(ScraperApiHelperContext);
	const cities = scraper.userHelper.getCities();
	const [activeFilters, setActiveFilters] = useState({
		city: cities[0],
		view: getPossibleViewOptions(cities[0], scraper)[0],
	});
	const updateFilter = (selectedFilters) => {
		if (areFiltersValueDifferent(activeFilters, selectedFilters))
			setActiveFilters(selectedFilters);
	};
	const isCEI = activeFilters.view.value === "CEI";
	const promotions = getPGEPromotionOptions(isCEI, !isCEI);
	const filterColumnSize = isCEI ? 4 : 3;

	return (
		<Section>
			<SectionHeader title={t("dashboard").capfirst()} />
			<SectionContent>
				<Container fluid>
					<Row
						className="mb-4 gap-4"
						style={{
							paddingLeft: "0.75rem",
							paddingRight: "0.75rem",
						}}
					>
						{cities.length !== 1 ? (
							<Col lg={filterColumnSize}>
								<Filters
									cities={cities}
									onFilter={updateFilter}
								/>
							</Col>
						) : null}
						{promotions.map((promotion) => (
							<Col
								key={`promotion_${promotion.value}`}
								lg={
									(12 - cities.length === 1
										? filterColumnSize
										: 0) / promotions.length
								}
							>
								<PromotionStatus
									city={activeFilters.city}
									promotion={promotion}
									detailPath={
										isCEI
											? `/master?promotion[]=${promotion.value}`
											: `/bachelor?city=${activeFilters.city.value}&promotion=${promotion.value}`
									}
								/>
							</Col>
						))}
					</Row>

					<Row
						className="mb-1"
						style={{
							paddingLeft: "0.75rem",
							paddingRight: "0.75rem",
						}}
					>
						<AlertCards
							city={activeFilters.city}
							isCEI={activeFilters.view.value === "CEI"}
							promotions={promotions.map(
								(promotion) => promotion.value
							)}
						/>
					</Row>
					<Row className="mb-4">
						<Col lg={12}>
							<InternshipProgress
								cities={[activeFilters.city]}
								promotions={promotions.map(
									(promotion) => promotion.label
								)}
								detailPath={`/internships_progression?cities=${activeFilters.city.value}`}
							/>
						</Col>
					</Row>
				</Container>
			</SectionContent>
			<Tooltip id="dashboard-tooltip" />
		</Section>
	);
}
