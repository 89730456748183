import { t } from "i18next";
import SauronMantineDataTable from "../Sauron/SauronMantineDataTable";
import LoginRow from "../Utils/LoginRow";
import ProjectsCoupleExpandableComponent from "./ProjectsExpendableComponent";

export default function ProjectsDataTable({
	data = [],
	isLoading,
	isFetching,
}) {
	const maxMembers =
		Math.max(...data.map((group) => group.group.members.length)) - 1;
	const columns = [
		{
			id: "leader",
			accessorFn: (row) => row.group.leader.login,
			header: `${t("leader").capfirst()}`,
			Cell: ({ row }) => (
				<LoginRow
					login={row.original.group.leader.login}
					city={row.original.group.leader.promo.city.name}
					pageTooltip="projects-student-tooltip"
				/>
			),
		},
		...Array.from({ length: maxMembers }, (_, i) => ({
			id: `member${i + 1}`,
			accessorFn: (row) =>
				row.group.members[i + 1] ? row.group.members[i + 1].login : "",
			header: `${t("member").capfirst()} ${i + 1}`,
			Cell: ({ row }) =>
				row.original.group.members[i + 1] ? (
					<LoginRow
						login={row.original.group.members[i + 1].login}
						city={row.original.group.members[i + 1].promo.city.name}
						pageTooltip="projects-student-tooltip"
					/>
				) : null,
		})),
		{
			id: "commits",
			accessorFn: (row) => row.total_stats.c,
			header: `${t("commits").capfirst()}`,
		},
		{
			id: "lines_of_code",
			accessorFn: (row) => row.total_stats.a - row.total_stats.d,
			header: `${t("lines of code").capfirst()}`,
		},
	];

	const exportConfig = {
		leader: "group.leader.login",
		commits: "total_stats.c",
		additions: "total_stats.a",
		deletions: "total_stats.d",
		lines_of_code: (row) => row.total_stats.a - row.total_stats.d,
	};
	for (let i = 0; i < maxMembers; i++) {
		exportConfig[`member${i + 1}`] = (row) =>
			row.group.members[i + 1]?.login ?? "";
	}

	return (
		<SauronMantineDataTable
			title={t("groups").capfirst()}
			data={data}
			columns={columns}
			tableProps={{
				enableExpandAll: false,
				renderDetailPanel: ({ row }) => (
					<ProjectsCoupleExpandableComponent
						data={row.original}
						isExpanded={row.getIsExpanded()}
					/>
				),
			}}
			exportConfig={{ columns: exportConfig, title: "projects_stats" }}
			isFetching={isFetching}
			isLoading={isLoading}
			isCard
		/>
	);
}
