import { Link } from "react-router-dom";

import EpitechLogo from "../../Assets/logo_epitech_blue.png";

export default function LoginRow({ login, gandalfId, pageTooltip, city }) {
	const externalLink = () => {
		let link = `https://intra.epitech.eu/user/${login}`;
		if (gandalfId) {
			link = `https://gandalf.epitech.eu/local/graph/view.php?userid=${gandalfId}`;
		}
		return (
			<a className="mr-1" href={link}>
				<img
					height={20}
					src={EpitechLogo}
					alt="epitech intra redirection"
				/>
			</a>
		);
	};

	return (
		<div
			style={{
				whiteSpace: "nowrap",
				overflow: "hidden",
				textOverflow: "ellipsis",
			}}
		>
			{externalLink()}
			<Link
				to={`/student/${login}`}
				data-tooltip-id={pageTooltip}
				data-tooltip-content={`${login};${city}`}
			>
				{login}
			</Link>
		</div>
	);
}
