import { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { t } from "../../Config/i18n";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import { areFiltersValueDifferent } from "../Utils/Filters";
import StudentTooltip from "../Utils/StudentTooltip";
import { separateFiltersOptions } from "../Utils/Utils";
import DatatablesContainer from "./DatatablesContainer";
import Filters from "./Filters";
import TepitechResultsChart from "./TepitechResultsChart";

export default function TepitechAlerts() {
	const areRequestEnabled = useRef(false);
	const [activeFilters, setActiveFilters] = useState({
		cities: [],
		curriculums: [],
		promotions: [],
		alerts: false,
	});

	const updateActiveFilters = (selectedFilters) => {
		if (areFiltersValueDifferent(activeFilters, selectedFilters)) {
			setActiveFilters(selectedFilters);
		}
		areRequestEnabled.current = true;
	};

	const { labels: selectedCurriculums } = separateFiltersOptions(
		activeFilters.curriculums
	);
	const { labels: selectedPromotions } = separateFiltersOptions(
		activeFilters.promotions
	);
	return (
		<Section>
			<SectionHeader title={t("Tepitech alerts").capfirst()} />
			<SectionContent>
				<Container fluid>
					<Row>
						<Col lg={3} className="mb-3">
							<Filters onFilter={updateActiveFilters} />
						</Col>
						<Col lg={9} className="mb-3">
							<TepitechResultsChart
								cities={activeFilters.cities}
								curriculum={selectedCurriculums}
								promotion={selectedPromotions}
							/>
						</Col>
						<Col lg={12}>
							<DatatablesContainer
								cities={activeFilters.cities}
								curriculums={selectedCurriculums}
								promotions={selectedPromotions}
								alertsOnly={activeFilters.alerts}
								areRequestEnabled={areRequestEnabled.current}
							/>
						</Col>
					</Row>
				</Container>
			</SectionContent>
			<Tooltip
				id="bachelor-tooltip"
				style={{ textAlign: "center", width: 400 }}
			/>
			<StudentTooltip tooltipId="bachelor-student-tooltip" />
		</Section>
	);
}
