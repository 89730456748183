import { useContext, useEffect } from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { Box, BoxFooterButton, BoxTitle } from "../AdminLTE3/Box";
import FiltersForm from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { getCurrentAcademicYear, nValues } from "../Utils/Utils";
import { useCitiesQuery, useCurriculumsQuery } from "./Queries";

export default function Filters({ onFilter }) {
	const scraper = useContext(ScraperApiHelperContext);
	const citiesOptions = scraper.userHelper.getCities();
	const TEPITECH_MODULE = "B-ANG-058";
	const isResponsible = scraper.userHelper
		.getSupervisedModules()
		.includes(TEPITECH_MODULE);
	const { data: fetchedCities } = useCitiesQuery(isResponsible);
	const { data, error } = useCurriculumsQuery();
	const { filters, setFilterOptions, setSelectedOption, applyFilters } =
		useFilters({
			cities: {
				label: t("cities"),
				type: "select",
				multiple: true,
				options: citiesOptions,
				default:
					citiesOptions?.length === 1 && !isResponsible
						? [citiesOptions[0]]
						: [],
			},
			curriculums: {
				label: t("curriculums"),
				type: "select",
				multiple: true,
				options: [],
				default: [],
			},
			promotions: {
				label: t("promotions"),
				type: "select",
				multiple: true,
				options: nValues(
					getCurrentAcademicYear() + 3,
					3
				).toFilterOptions(),
				default: [],
			},
			alerts: {
				label: t("students in alert only"),
				type: "bool",
				default: false,
			},
		});

	useEffect(() => {
		if (!fetchedCities) return;
		setFilterOptions("cities", fetchedCities);
	}, [fetchedCities]);

	useEffect(() => {
		if (!data) return;
		setFilterOptions("curriculums", data);
	}, [data]);

	useEffect(() => {
		if (!error) return;
		toast.error(
			`Tepitech Alerts Filters: ${t("An error has occured")}: ${error.message}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error]);

	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("filters").capfirst()} />
			</Card.Header>
			<Card.Body>
				<FiltersForm filters={filters} onChange={setSelectedOption} />
			</Card.Body>
			<Card.Footer>
				<BoxFooterButton
					label={t("filter").capfirst()}
					onClick={() => applyFilters(onFilter)}
				/>
			</Card.Footer>
		</Box>
	);
}
