import { useEffect, useState } from "react";

export default function CelebrationCursorLogic({ children }) {
	const celebration = import.meta.env.VITE_CELEBRATION;
	if (
		(celebration !== "halloween" && celebration !== "christmas") ||
		localStorage.getItem("disableCelebration") === "true"
	)
		return null;
	const [cursorPosition, setCursorPosition] = useState({ left: 0, top: 0 });

	const handleCursor = (e) => {
		setCursorPosition({ left: e.pageX, top: e.pageY });
	};

	useEffect(() => {
		document.addEventListener("mousemove", handleCursor);
		return () => {
			document.removeEventListener("mousemove", handleCursor);
		};
	}, []);

	return (
		<div
			style={{
				position: "absolute",
				left: cursorPosition.left,
				top: cursorPosition.top,
				pointerEvents: "none",
			}}
		>
			{children}
		</div>
	);
}
