import { Navbar, Text, NavLink, ScrollArea, Group } from "@mantine/core";
import { useContext } from "react";
import { ScraperApiHelperContext } from "../../contexts.js";
import { getSauronLogo, hasPermissions } from "../Utils/Utils.jsx";
import { t } from "../../Config/i18n.jsx";
import { useNavigate } from "react-router-dom";
import { SauronRoutes } from "../../Config/Routes.jsx";

const PAGES = {
	"full overview": [
		SauronRoutes.dashboard,
		SauronRoutes.bachelor,
		SauronRoutes.master,
		SauronRoutes.cities,
		SauronRoutes.investment_overview,
		SauronRoutes.intuitu_personae,
		SauronRoutes.investment_reports,
	],
	"module validations": [
		SauronRoutes.tesseract,
		SauronRoutes.tesseract_configuration,
		SauronRoutes.evaluations,
		SauronRoutes.cheaters,
		SauronRoutes.professional_communication,
	],
	"alerts overview": [
		SauronRoutes.transcripts,
		SauronRoutes.student_presences,
		SauronRoutes.module_alerts,
		SauronRoutes.tepitech_alerts,
		SauronRoutes.internship_part_time,
		SauronRoutes.diversity_overview,
	],
	"stats": [
		SauronRoutes.module_validations,
		SauronRoutes.tepitech_progress,
		SauronRoutes.internship_progression,
		SauronRoutes.projects,
	],
	"administration": [
		SauronRoutes.scraping_history,
		SauronRoutes.admin_panel,
		SauronRoutes.jwt_manager,
	],
};

const SauronSideMenu = () => {
	const route = location.pathname;
	const logo = getSauronLogo();
	const navigate = useNavigate();
	const buildPages = (key) => {
		const scraper = useContext(ScraperApiHelperContext);
		const pages = PAGES[key].filter((page) =>
			hasPermissions(page, scraper.api.getSauronUserInfo())
		);
		if (pages.length === 0) {
			return null;
		}
		const layouts = [
			<Text fw={700} key={key} mb="xs" mt="xs">
				{t(key).capfirst()}
			</Text>,
		];
		return layouts.concat(
			pages.map((page) => (
				<NavLink
					label={t(page.label).capfirst()}
					icon={page.icon}
					active={route === page.path}
					variant="filled"
					onClick={() => navigate(page.path)}
					key={`${key}.${page.path}`}
				/>
			))
		);
	};

	return (
		<Navbar pl="xs" pr="xs" width={{ base: 300 }} sx={{ zIndex: 1000 }}>
			<Navbar.Section sx={{ borderBottom: "1px solid grey" }}>
				<Group
					mih={60}
					position="left"
					spacing="md"
					onClick={() => navigate(".")}
					style={{ cursor: "pointer" }}
				>
					<img
						src={logo}
						alt="Sauron Logo"
						style={{
							height: "38px",
							width: "38px",
						}}
					/>
					<Text fw={700} fz="xl">
						Sauron
					</Text>
				</Group>
			</Navbar.Section>
			<Navbar.Section grow mt="md" component={ScrollArea}>
				{Object.keys(PAGES).map(buildPages)}
			</Navbar.Section>
		</Navbar>
	);
};

export default SauronSideMenu;
